import { ethers } from "ethers";
export const FlareRpc = "https://flare-api.flare.network/ext/C/rpc";
export const SongbirdRpc = "https://songbird-api.flare.network/ext/C/rpc";
export const Coston2Rpc = "https://coston2-api.flare.network/ext/C/rpc";
export const FLRMonitorBaseUrl = "https://flare-ftso-monitor.flare.network/price?currency=XRP&startTime=30m&providerAddress=";
export const SGBMonitorBaseUrl = "https://songbird-ftso-monitor.flare.network/price?currency=XRP&startTime=30m&providerAddress=";

export const Coston2Provider = new ethers.providers.JsonRpcProvider(Coston2Rpc);
export const SongbirdProvider = new ethers.providers.JsonRpcProvider(SongbirdRpc);
export const FlareProvider = new ethers.providers.JsonRpcProvider(FlareRpc);


export const C2_VISTOKEN_ADD = "0xc19fBD843eD3352CB76267b15914a67821Aa27a4"
export const C2_ARC_ADD = "0x364a4596653096c6F06179d6c1bf224DAc3E94d2"
export const C2_NFTRewardManager_ADD = "0x001831b4125c3840FF7e4e8DD535c718CFB0F2E3"
export const C2_Vote4Delegation_ADD = "0x38D151301f91FCeEd932d3ea58C5D81c3d8c4F9d"
export const C2_Vault_ADD = "0x670F879E8456Fc214783dCFdCFfEE1E2D8182BF7"
export const C2_VistionDist_ADD = "0x010E0C333E62671CbEB7d49F4387C487FA1EBb68";


export const SGB_WNat = "0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED";
export const SGB_ORACLE = "0xCdB019C0990c033724DA55f5A04bE6fd6ec1809d";
export const SGB_ORACLERC20 = "0xD7565b16b65376e2Ddb6c71E7971c7185A7Ff3Ff";
export const SGB_PROERC20 = "0xf810576A68C3731875BDe07404BE815b16fC0B4e";
export const SGB_Vault_ADD = "0x3a107120b7065d13404B71C04A3e0AB25DB83cE1";
export const SGB_Vote4Delegation_ADD = "0x034AAE35b5B0e0470bc528e584e26955FaCF2643";
