import { useEffect, useState, useCallback } from "react";
import useConnect from "../../hooks/useConnect";
import { useOracle, useOracleVault, useProviders, useOracleVote4Delegation, useCurrentRewardEpoch } from "../../hooks/useContracts";
import { UnknownProvider } from "../../config/providers";
import { ProviderProps } from "../../types";
import { addressStrWithDot, notifyError } from "../../lib";
import ProvidersModal from "../archetypes/components/ProvidersModal";
import DelegateeComponent from "../archetypes/components/DelegateeComponent";
import { CountdownTimer } from "../../components/CountdownTimer";

import { ReactComponent as PlusSvg } from "../../assets/plus.svg";
import { ReactComponent as MinusSvg } from "../../assets/minus.svg";
import VISLogoSvg from "../../assets/VIS.svg";
import OSLogoPng from "../../assets/icons/icon-512x512.png";
import PROLogoPng from "../../assets/icons/pro-icon.png";
import flareSvg from "../../assets/FLR.svg";
import goldSGBSvg from "../../assets/gold_sgb.png"
import { SGB_ORACLERC20, SGB_PROERC20, SGB_WNat } from "../../config/const";
import ScaleLoader from "../../components/ScaleLoader";
import Modal from "../../components/Modal";


export const Oracle = () => {
  const { connect, chainId, account, active, switchToSongbird } = useConnect();
  const { epoch, startTime, duration } = useCurrentRewardEpoch();

  const { wNatOfVault, proERC20TokenOfVault, oracleERC20OfVault, totalVolume, curWNatOfAcc, curOracleERC20OfAcc, curProERC20OfAcc, pendingRewardOfVault, delegatedAmount, delegationsOfVault, claimableRewardOfVault, delegatable, isWithdrawalOracle, isWithdrawalPro, isWithdrawalWNat,lockedAmountOfVault, claimERC20OfVault, claimRewardOfVault, delegate2TopDelegatees } = useOracleVault();
  const { balance, maxSupply, walletUris, oracleIdsOfAccount } = useOracle();
  const { votedDelegations,isLoadingVote,currentVotingAdd, votingToDelegatee } = useOracleVote4Delegation();
  const providers: ProviderProps[] = useProviders();

  const [isOpenDelegateModal, setIsOpenDelegateModal] = useState(false);
  const [currentTimeStamp, setCurrentTimeStamp] = useState(0);



  useEffect(() => {
    if (!chainId) return;
    if (chainId !== 19) switchToSongbird();
  }, [chainId])

  const setCurrentTime = useCallback(() => {
    setCurrentTimeStamp(Math.floor(new Date().getTime() / 1000));
  }, [])

  useEffect(() => {
    setCurrentTime();
  }, [])

  return (
    <div className="grid grid-cols-1 gap-5">
      <div className="py-1 w-[90%] sm:w-[320px] md:w-full max-w-md mx-auto">

        <div className="justify-center w-full max-w-md mx-auto flex">
          <img src={OSLogoPng} alt="gift avatar" className="w-[75px] mb-5" />
        </div>

        <h1 className="text-center text-[24px] bold font-bold">👑The Oracles Vault🧙‍♂️</h1>
        <p className="text-[16px] text-[#9ca3af] leading-5 break-words">
          The Oracle Vault is a unique mechanism that gives NFTs the ability to manage a shared FTSO delegation and earn rewards.
          Each Oracle NFT holds a 1 in 22,222 share of the Oracle Vault.
          Each Oracle NFT is like a key to the Oracle Vault and can claim/withdraw the contents of the vault when they are unlocked.
          Each Oracle NFT counts as a Vote that can be used to choose which FTSOs the WSGB in the Vault is Delegated to.
        </p>
        <a href="https://docs.oracleswap.io/oracle-swap-nfts/the-oracles-nfts/oracle-vault" className="text-[20px] text-[#0080F5] font-[700]">LEARN MORE</a>
      </div>
      {
        account && active ? (
          <>
            <div className="rounded-[16px] dark:bg-[#202231] shadow-dark-1000 shadow-md w-[90%] sm:w-[320px] md:w-full px-5 py-3 max-w-md mx-auto">
              <div className="">
                <div className="flex">
                  <div className="text-[20px] select-none font-bold">
                    ♎Your ORACLES🧙‍♂️
                  </div>
                </div>
              </div>


              <div className="rounded-[16px] text-[14px] text-start p-4 border border-gray-700 hover:border-gray-600 bg-[#161622]">
                {balance && walletUris ? (<div className="my-1 rounded-[16px] bg-white dark:bg-[#161622]">
                  <div className="scrollbar-h-[4px] scrollbar-thumb-[#E9B83B] scrollbar-track-[#484848] scrollbar-thumb-rounded-[9px] scrollbar-track-rounded-[9px] flex overflow-x-auto space-x-3 pb-[20px]">
                    {oracleIdsOfAccount?.map((id, index) => {
                      return (<div className="flex-shrink-0" key={index}>
                        <h6 className="text-[14px]">ID: <mark className="text-[#E9B83B] bg-transparent">{id}</mark> </h6>
                        <img src={walletUris[index]} alt="" className="w-[105px]" />
                      </div>)
                    })}
                  </div>
                </div>) : null}

                <div className="text-left flex justify-between">
                </div>
                <div className="flex pt-2  items-center">
                  <div className="hidden md:flex justify-center">
                    <img src={goldSGBSvg} alt="provider logo" className="w-[60px]" />
                  </div>
                  <div className="flex flex-col justify-center text-[12px] md:text-[14px] line-height-[20px] text-gray-400 dark:text-gray-300">
                    <p className="leading-4 text-[14px]">⚖️ Balance : <mark className="text-[#E9B83B] bg-transparent">{balance ? balance : 0} ORACLE</mark></p>
                    <p className="leading-4 text-[14px]">🏛️ ORACLE Vault Share : <mark className="text-[#E9B83B] bg-transparent">{balance ? balance : ""}/{maxSupply} =  {balance && maxSupply ? ((balance / maxSupply) * 100).toFixed(4) : 0}%</mark></p>
                    <p className="leading-4 text-[14px]">👽 1 ORACLE = <mark className="text-[#E9B83B] bg-transparent">{maxSupply && maxSupply > 0 ? (totalVolume / maxSupply).toFixed(2) : 0} WSGB</mark></p>
                    <p className="leading-4 text-[14px]">🔒 Total WSGB Value: <mark className="text-[#E9B83B] bg-transparent">{maxSupply && balance ? (totalVolume * balance / maxSupply).toFixed(2) : 0} / {totalVolume.toFixed(2)}</mark></p>
                  </div>
                </div>
                <div className="py-3 text-xs">
                  {
                    wNatOfVault.unlockTimestamp > currentTimeStamp ? (
                      <div className="my-1 text-gray-400 dark:text-gray-300">
                        <div className="text-[11px] md:text-[12px]"><p>ORACLE VAULT OPENS IN : </p></div>
                        <CountdownTimer target={wNatOfVault.unlockTimestamp} startTime={1688446800} callBack={setCurrentTime} />
                      </div>
                    ) : (
                      <div className="my-1 flex justify-between text-gray-400 dark:text-gray-300">
                        <div className="text-[16px]"><p>VAULT OPENED: </p></div>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="text-center flex justify-end">
                <a href="https://sparklesnft.com/collection/songbird/theoracles/" className="text-[16px] font-bold text-[#0080F5] px-1">See them on Sparkles</a>
              </div>

              <div className="flex justify-between my-2">
                <div className="text-[18px] leading-5 select-none font-bold">
                  Your Vault Rewards
                </div>
              </div>

              <div className="rounded-[16px] text-[12px] line-height text-gray-400 dark:text-gray-300 text-start p-3 border border-gray-700 hover:border-gray-600 bg-[#161622]">
                <div className="md:flex justify-between">
                  <div className="flex px-2 pt-2  items-center md:w-[50%]">
                    <div className="mr-2 flex justify-center">
                      <img src={VISLogoSvg} alt="provider logo" className="w-[30px] h-[30px]" />
                    </div>
                    <div className="flex flex-col justify-center line-height-[20px]">
                      <p className="leading-4 text-[12px]">Pending : <mark className="text-[#E9B83B] bg-transparent">0.00 <span className="text-[8px]"></span></mark></p>
                      <p className="leading-4 text-[12px]">Balance : <mark className="text-[#E9B83B] bg-transparent">0.00 <span className="text-[8px]"></span></mark></p>
                    </div>
                  </div>


                  <div className="px-2 pt-2 md:w-[50%]">
                    <div className="flex items-center">
                      <div className="mr-2 flex justify-center">
                        <img src={OSLogoPng} alt="provider logo" className="w-[30px] h-[30px]" />
                      </div>
                      <div className="flex flex-col justify-center line-height-[20px]">
                        <p className="leading-4 text-[12px]">LOCKED : <mark className="text-[#E9B83B] bg-transparent">{balance && oracleERC20OfVault.unlockTimestamp > currentTimeStamp ? (balance * oracleERC20OfVault.remainPerNFT + oracleERC20OfVault.claimableAmount).toFixed(2) : balance ? (balance * oracleERC20OfVault.remainPerNFT).toFixed(2) : "0.00"} <span className="text-[8px]">ORACLE</span></mark></p>
                        {
                          oracleERC20OfVault.unlockTimestamp < currentTimeStamp ? (
                            <p className="leading-4 text-[12px]">Pending : <mark className="text-[#E9B83B] bg-transparent">{oracleERC20OfVault.claimableAmount.toFixed(2)} <span className="text-[8px]">ORACLE</span></mark></p>
                          ) : null
                        }
                        <p className="leading-4 text-[12px]">Balance : <mark className="text-[#E9B83B] bg-transparent">{curOracleERC20OfAcc.toFixed(2)} <span className="text-[8px]">ORACLE</span></mark></p>
                      </div>
                    </div>
                    {oracleERC20OfVault.unlockTimestamp > currentTimeStamp ? <CountdownTimer target={oracleERC20OfVault.unlockTimestamp} startTime={1688446800} callBack={setCurrentTime} /> : null}
                  </div>
                </div>
                <div className="md:flex justify-between">
                  <div className="px-2 pt-2 md:w-[50%]">
                    <div className="flex items-center">
                      <div className="mr-2 flex justify-center">
                        <img src={PROLogoPng} alt="provider logo" className="w-[30px] h-[30px]" />
                      </div>
                      <div className="flex flex-col justify-center line-height-[20px]">
                        <p className="leading-4 text-[12px]">LOCKED : <mark className="text-[#E9B83B] bg-transparent">{balance && proERC20TokenOfVault.unlockTimestamp > currentTimeStamp ? (balance * proERC20TokenOfVault.remainPerNFT + proERC20TokenOfVault.claimableAmount).toFixed(2) : balance ? (balance * proERC20TokenOfVault.remainPerNFT).toFixed(2) : "0.00"} <span className="text-[8px]">PRO</span></mark></p>
                        {
                          proERC20TokenOfVault.unlockTimestamp < currentTimeStamp ? (
                            <p className="leading-4 text-[12px]">Pending : <mark className="text-[#E9B83B] bg-transparent">{proERC20TokenOfVault.claimableAmount.toFixed(2)} <span className="text-[8px]">PRO</span></mark></p>
                          ) : null
                        }
                        <p className="leading-4 text-[12px]">Balance : <mark className="text-[#E9B83B] bg-transparent">{curProERC20OfAcc.toFixed(2)} <span className="text-[8px]">PRO</span></mark></p>
                      </div>
                    </div>
                    {proERC20TokenOfVault.unlockTimestamp > currentTimeStamp ? <CountdownTimer target={proERC20TokenOfVault.unlockTimestamp} startTime={1688446800} callBack={setCurrentTime} /> : null}
                  </div>

                  <div className="px-2 pt-2 md:w-[50%]">
                    <div className="flex items-center">
                      <div className="mr-2 flex justify-center">
                        <img src={goldSGBSvg} alt="provider logo" className="w-[30px] h-[30px]" />
                      </div>
                      <div className="flex flex-col justify-center line-height-[20px]">
                        <p className="leading-4 text-[12px]">LOCKED : <mark className="text-[#E9B83B] bg-transparent">{balance && wNatOfVault.unlockTimestamp > currentTimeStamp ? (balance * wNatOfVault.remainPerNFT + wNatOfVault.claimableAmount).toFixed(2) : balance ? (balance * wNatOfVault.remainPerNFT).toFixed(2) : "0.00"} <span className="text-[8px]">WSGB</span></mark></p>
                        {
                          wNatOfVault.unlockTimestamp < currentTimeStamp ? (
                            <p className="leading-4 text-[12px]">Pending : <mark className="text-[#E9B83B] bg-transparent">{wNatOfVault.claimableAmount.toFixed(2)} <span className="text-[8px]">WSGB</span></mark></p>
                          ) : null
                        }
                        <p className="leading-4 text-[12px]">Balance : <mark className="text-[#E9B83B] bg-transparent">{curWNatOfAcc.toFixed(2)} <span className="text-[8px]">WSGB</span></mark></p>
                      </div>
                    </div>
                    {wNatOfVault.unlockTimestamp > currentTimeStamp ? <CountdownTimer target={wNatOfVault.unlockTimestamp} startTime={1688446800} callBack={setCurrentTime} /> : null}
                  </div>
                </div>
              </div>

              <div className="md:flex justify-between mt-5">
                <button className="bg-[#E9B83B] py-1 px-2 my-2 rounded-[9px] w-full md:w-[106px] disabled:opacity-[40%]" disabled={!isWithdrawalOracle} onClick={() => claimERC20OfVault(SGB_ORACLERC20)}>
                  <div className="flex items-center justify-center">
                    <span className="text-[16px] font-bold ml-2">CLAIM</span>
                    <img src={OSLogoPng} alt="oracle logo" className="w-[25px] ml-2" />
                  </div>
                </button>
                <button className="bg-[#E9B83B] py-1 px-2 my-2 rounded-[9px] w-full md:w-[106px] disabled:opacity-[40%]" disabled={!isWithdrawalPro} onClick={() => claimERC20OfVault(SGB_PROERC20)}>
                  <div className="flex items-center justify-center">
                    <span className="text-[16px] font-bold ml-2">CLAIM</span>
                    <img src={PROLogoPng} alt="pro logo" className="w-[25px] ml-2" />
                  </div>
                </button>
                <button className="bg-[#E9B83B] py-1 px-2 my-2 rounded-[9px] w-full md:w-[106px] disabled:opacity-[40%]" disabled={!isWithdrawalWNat} onClick={() => claimERC20OfVault(SGB_WNat)}>
                  <div className="flex items-center justify-center">
                    <span className="text-[16px] font-bold ml-2">CLAIM</span>
                    <img src={goldSGBSvg} alt="pro logo" className="w-[25px] ml-2" />
                  </div>
                </button>
              </div>

            </div>

            <div className="rounded-[16px] dark:bg-[#202231] shadow-dark-1000 shadow-md px-5 w-[90%] sm:w-[320px] md:w-full py-3 my-2  max-w-md mx-auto">
              <div className="flex justify-between ">
                <div className="text-[20px] leading-5 select-none font-bold">
                  🏛️The ORACLE Vault👽
                </div>
              </div>
              <div className="text-sm leading-5 p-2">
                <p className="text-[15px] text-[#9ca3af]">
                  Cast your vote on which FTSO The Vault is Delegated to. This determines the growth of the pool!
                </p>
              </div>
              <div className="p-4 rounded-[16px] bg-white dark:bg-[#161622] text-[14px]">
                <div className="w-full text-gray-400 dark:text-gray-300">
                  <div className="flex justify-between">
                    <span>ORACLE Vault Size:</span>
                    <span className="text-[#E9B83B]">
                      {wNatOfVault.balance.toFixed(2)} WSGB
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Pending Del Rewards:</span>
                    <span className="text-[#E9B83B]">
                      {claimableRewardOfVault.toFixed(2)} WSGB
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Active:</span>
                    <span className="text-[#E9B83B]">
                      {lockedAmountOfVault.toFixed(2)} WSGB
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Next Epoch:</span>
                    <span className="text-[#E9B83B]">
                      <CountdownTimer target={startTime + duration} startTime={startTime} callBack={undefined} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="pb-0 mt-6">
                  {/* {`Current Epoch (${epoch})`}{" "} */}
                </div>
                {
                  delegationsOfVault.map((item, index) => {
                    return (

                      <div key={index} className="flex items-center justify-between border rounded-[14px] border-gray-600  px-4 py-2 hover:bg-gray-50 dark:hover:bg-slate-700 dark:hover:border-gray-600 dark:bg-[#161622] cursor-pointer select-none mb-2">
                        <div className="flex items-center tracking-tight text-gray-200">
                          <img
                            src={
                              providers.filter((fpvd) => {
                                return fpvd.address === item.address;
                              })[0]?.logoURI ?? UnknownProvider.logoURI
                            }
                            className="w-6 mr-4"
                            alt=""
                          />
                          <span className="">
                            {
                              providers.filter((fpvd) => {
                                return fpvd.address === item.address;
                              })[0]?.name ?? UnknownProvider.name
                            }
                          </span>
                        </div>
                        <div className="text-sm text-gray-400">
                          {`${item.amount.toFixed(2)} SGB`}
                        </div>
                      </div>
                    );
                  })
                }
              </div>


              <div className="mb-4">
                <div className="flex justify-between w-full pt-2 mt-4 text-gray-400 border-t border-gray-700 dark:border-gray-700 hover:border-gray-800 dark:hover:border-gray-600">
                  <div className="">Growing:</div>
                  <div className="">
                    <span>
                      {pendingRewardOfVault.toFixed(2) + " "}
                    </span>
                    <span className="text-sm uppercase">SGB
                    </span>
                  </div>
                </div>
                <div className="flex justify-between w-full pt-2 mt-4 text-gray-400 border-t border-gray-700 dark:border-gray-700 hover:border-gray-800 dark:hover:border-gray-600">
                  <div className="">Claimable:</div>
                  <div className="">
                    <span>
                      {claimableRewardOfVault.toFixed(2) + " "}
                    </span>
                    <span className="text-sm uppercase">
                      SGB
                    </span>
                  </div>
                </div>
                <div className="flex justify-between w-full pt-2 mt-4 text-gray-400 border-t border-gray-700 dark:border-gray-700 hover:border-gray-800 dark:hover:border-gray-600">
                  <div className="">Total:</div>
                  <div className="">
                    <span>
                      {(pendingRewardOfVault + claimableRewardOfVault).toFixed(2) + " "}
                    </span>
                    <span className="text-sm uppercase ">
                      SGB
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-2">
                <div className="text-[16px] leading-5 select-none font-bold">
                  🌎Global Functions
                </div>
              </div>
              <div className="text-sm leading-5 p-2">
                <p className="text-[15px] text-[#9ca3af]">
                  By calling the DELEGATE function you are locking in the Top 2 voted FTSOs for the whole pool.
                  By calling these CLAIM functions you are claiming the Rewards for the whole ORACLE Vault.
                  Rewards are auto wrapped and locked in the vault.
                  Only ORACLE Holders can call these functions.
                </p>
              </div>
              <div className="md:flex justify-between text-sm mx-1">
                <button className="text-center bg-[#DAA520] py-2 px-1 my-2 mr-2 rounded-[12px] w-full md:w-[55%] disabled:opacity-[40%]" disabled={claimableRewardOfVault === 0} onClick={() => claimRewardOfVault()}>
                  <span className="text-[16px]">Claim Del Rewards</span>
                </button>
                <button className="text-center bg-[#DAA520] py-2 px-1 my-2 rounded-[12px] w-full md:w-[45%] disabled:opacity-[40%]" disabled={!delegatable} onClick={() => delegate2TopDelegatees()}>
                  <span className="text-[16px]">DELEGATE</span>
                </button>
              </div>
              <div className="flex justify-between mt-2">
                <div className="text-[16px] leading-5 select-none font-bold">
                  ✅ Vote
                </div>
              </div>
              <div className="leading-5 my-1">
                <p className="text-[15px] text-[#9ca3af]">
                  Use your ORACLE vote power to vote on which FTSOs the Vault delegates to. The more ORACLES you have the more votes you have.
                </p>
              </div>

              <div className="rounded-[14px] text-center py-1 border border-gray-700 hover:border-gray-800 dark:hover:border-gray-600 bg-white dark:bg-[#161622]  cursor-pointer hover:shadow-sm">
                <div
                  className="flex items-center pl-4 h-14"
                  onClick={() => balance && setIsOpenDelegateModal(true)}
                >
                  <PlusSvg className="w-5 h-5 text-gray-300 fill-current dark:text-gray-400" />
                  <span className="pl-5 font-semibold text-gray-300 dark:text-gray-400">
                    Vote For Delegation
                  </span>
                </div>
              </div>

              <div className="flex justify-between mt-2">
                <div className="text-[16px] leading-5 select-none font-bold">
                  🏆 Top 10 Voted FTSOs
                </div>
              </div>
              <div className="text-sm leading-5 p-2">
                <p className="text-[15px] text-[#9ca3af]">
                  The Top 2 FTSOs in this list will be locked in 50/50 at each Epoch.
                </p>
              </div>

              {
                votedDelegations.sort((a, b) => b.voted - a.voted).slice(0, 10).map((item, index) => {
                  let curProvider = providers.filter(fpvd => fpvd.address === item.delegatee)[0];
                  return (
                    <DelegateeComponent key={index} rank={index + 1} provider={curProvider} item={item} />
                  )
                })
              }


            </div>
          </>
        ) : (
          <div
            className="w-full max-w-md mx-auto text-center px-3 py-2 rounded-[14px] dark:bg-[#DAA520] cursor-pointer"
            onClick={() => {
              if (!active) {
                connect().catch((e) => {
                  if (e.name === "UnsupportedChainIdError") {
                    notifyError("Please connect to Songbird or Flare");
                  }
                });
              }
            }}
          >
            <span>Connect Wallet</span>
          </div>
        )
      }
      {isOpenDelegateModal ? (
        <ProvidersModal
          handleCancel={() => setIsOpenDelegateModal(false)}
          isOpen={isOpenDelegateModal}
          setting={votingToDelegatee}
        />
      ) : (
        <></>
      )}
      <Modal isOpen={isLoadingVote}>
        <div className="relative p-6">
          <div className="flex justify-center py-6">
            <ScaleLoader color="#36d7b7" />
          </div>
          <div className="text-center">
            <p className="text-md font-thin">Voting {addressStrWithDot(currentVotingAdd, 8, 6)} for Delegation ...</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}