import { useEffect, useState, useCallback } from "react";
import moment from "moment";
import wrapsongbirdSvg from "../../assets/wrapsongbird.svg";
import wrapflareSvg from "../../assets/WFLR.svg";
import { ReactComponent as PlusSvg } from "../../assets/plus.svg";
import {
  useBalances,
  useCurrentRewardEpoch,
  useDelegatedPercentages,
  useDelegate,
  useArcRewardManager
} from "../../hooks/useContracts";
import { ProviderProps } from "../../types";
import { useMetrics } from "../../hooks/useMetrics";
import useConnect from "../../hooks/useConnect";
import ProvidersModal from "./components/ProvidersModal";
import ProviderConfigureModal from "./components/ProviderConfigureModal";
import { ProviderUndelegateConfirmation } from "./components/ProviderUndelegateConfirmation";
import { ProviderSaveConfirmation } from "./components/ProviderSaveConfirmation";
import { addressStrWithDot, notifyWarn, notifyError } from "../../lib";
import { UnknownProvider } from "../../config/providers";
import { MetricCard } from "./components/MetricCard";
import CircleLoader from "../../components/CircleLoader";
import ARCLogo from "../../assets/ARC_LOGO.png";

var metricOrder1 = 1;
var metricOrder2 = 1;
var metricOrder3 = 1;

export const Delegate = () => {
  const { wNat } = useBalances();
  const { connect, active, chainId } = useConnect();
  const { startTime, duration, activeAmount } = useCurrentRewardEpoch();
  const { delegations, update: updateDelegate } = useDelegatedPercentages();
  const { delegate, undelegate, loading } = useDelegate();
  const { isLoading, metricInfoes, providersArr: providers } = useMetrics();
  const { randomAssignedFtsoProvider, randomUpcomingFtsoProvider } = useArcRewardManager();
  const [providersArr, setProviderArr] = useState<ProviderProps[]>([]);
  const [remain, setRemain] = useState(0);
  const [maxPercentage, setMaxPercentage] = useState("");
  const [isOpenDelegateModal, setIsOpenDelegateModal] = useState(false);
  const [isOpenDelegateConfigureModal, setIsOpenDelegateConfigureModal] =
    useState(false);
  const [
    isOpenUndelegateConfirmationModal,
    setIsOpenUndelegateConfirmationModal,
  ] = useState(false);
  const [isOpenSaveConfirmationModal, setIsOpenSaveConfirmationModal] =
    useState(false);
  const [choosenProvider, setChoosenProvider] = useState({});
  const [delegatingAmountPercentage, setDelegatingAmountPercentage] =
    useState("");
  const [allocatedAmountPercentage, setAllocatedAmountPercentage] =
    useState("0");
  const handleDategationSlot1 = () => {
    if (!active) {
      return;
    }
    setIsOpenDelegateModal(true);
  };
  const handleDategationSlot2 = () => {
    if (!active) {
      return;
    }
    setIsOpenDelegateModal(true);
  };
  const handleCancelDelegateModal = () => {
    setIsOpenDelegateModal(false);
  };
  const handleOpenDelegateConfigureModal = (status: ProviderProps) => {
    setChoosenProvider(status);
    setIsOpenDelegateConfigureModal(true);
    var aa = delegations?.filter((provider, index) => {
      return provider.address !== status.address;
    });
    if (aa?.length === 0) {
      setMaxPercentage("100");
    } else if (aa !== undefined) {
      let remainPercen = (1 - aa[0].percentage).toFixed(2);
      let maxPercen = (100 * parseFloat(remainPercen)).toFixed(2);
      setMaxPercentage("" + maxPercen);
    }
  };
  const handleCancelDelegateConfigureModal = () => {
    setIsOpenDelegateConfigureModal(false);
  };
  const handleOpenDelegatingConfirmationModal = (
    type: string,
    amount: string
  ) => {
    if (type === "undelegate") {
      setIsOpenUndelegateConfirmationModal(true);
    } else {
      setIsOpenSaveConfirmationModal(true);
    }
    setDelegatingAmountPercentage(amount);
  };
  const handleCancelDelegatingConfirmationModal = (type: string) => {
    if (type === "undelegate") {
      setIsOpenUndelegateConfirmationModal(false);
    } else {
      setIsOpenSaveConfirmationModal(false);
    }
  };
  const handleDelegate = (address: string, amount: number) => {
    if (amount < 0) {
      notifyWarn("This request is not allowed because the amount is zero");

      return;
    }
    delegate(address, amount);
  };

  const handleUndelegate = (address: string) => {
    undelegate(address);
  };
  const sortBy = useCallback((key: string) => {
    if (providersArr.length < 1 || Object.keys(metricInfoes).length < 1) return;
    let temp = providersArr;
    if (key === "reward_rate") {
      let asc = metricOrder1;
      metricOrder1 = (-1 * metricOrder1);
      temp = temp.sort((a, b) => {
        let x = metricInfoes[b.address];
        let y = metricInfoes[a.address];
        if (x === undefined && y === undefined)
          return 0;
        else if (x === undefined)
          return asc;
        else if (y === undefined)
          return -1 * asc;
        return asc * (x.property.rewardRate - y.property.rewardRate);
      });
    } else if (key === "fee") {
      let asc = metricOrder2;
      metricOrder2 = (-1 * metricOrder2);
      temp = temp.sort((a, b) => {
        let x = metricInfoes[a.address];
        let y = metricInfoes[b.address];
        if (x === undefined && y === undefined)
          return 0;
        else if (x === undefined)
          return asc;
        else if (y === undefined)
          return -1 * asc;
        return asc * (x.property.fee - y.property.fee);
      });
    } else if (key === "votepower") {
      let asc = metricOrder3;
      metricOrder3 = (-1 * metricOrder3);
      temp = temp.sort((a, b) => {
        let x = metricInfoes[a.address];
        let y = metricInfoes[b.address];
        if (x === undefined && y === undefined)
          return 0;
        else if (x === undefined)
          return asc;
        else if (y === undefined)
          return -1 * asc;
        return asc * (x.property.votePower - y.property.votePower);
      });
    }
    else if (key === "assignedrandom")
      temp = temp.sort((a, b) => a.address !== randomAssignedFtsoProvider ? -1 : 1);
    else if (key === "upcomingrandom")
      temp = temp.sort((a, b) => a.address !== randomUpcomingFtsoProvider ? -1 : 1);

    setProviderArr([...temp])
  }, [providersArr, metricInfoes])

  useEffect(() => {
    if (providers.length < 1) return;
    setProviderArr(providers);
  }, [providers])
  useEffect(() => {
    if (startTime < 1 || duration < 1) return;
    let currentTime = new Date().getTime();
    setRemain((startTime + duration) - Math.floor(currentTime / 1000));
  }, [startTime, duration]);

  useEffect(() => {
    if (!delegations) return;
    var allocated = 0;
    for (const iterator of delegations) {
      allocated += 100 * iterator.percentage;
    }
    setAllocatedAmountPercentage("" + allocated);
  }, [delegations])

  useEffect(() => {
    if (!loading && isOpenDelegateModal) {
      setIsOpenDelegateModal(loading);
    }
    if (!loading && isOpenDelegateConfigureModal) {
      setIsOpenDelegateConfigureModal(loading);
    }
    if (!loading && isOpenSaveConfirmationModal) {
      setIsOpenSaveConfirmationModal(loading);
    }
    if (!loading && isOpenUndelegateConfirmationModal) {
      setIsOpenUndelegateConfirmationModal(loading);
    }
    if (!loading) {
      // updateDelegate();
    }
  }, [loading]);

  return (
    <>
      <div className="relative flex justify-center">
        <div className="rounded-[24px] dark:bg-[#202231] shadow-dark-1000 shadow-md px-3  py-1  md:w-96 w-[90%] sm:w-[320px]">
          <div className="p-2">
            <span className="text-base leading-5 tracking-tight cursor-pointer select-none text-secondary hover:text-white text-high-emphesis">
              Delegate
            </span>
            <div className="my-2 p-4 border rounded-[14px] border-gray-700 hover:border-gray-800 dark:hover:border-gray-600 bg-white dark:bg-[#161622] ">
              <div className="flex items-center justify-between pb-2">
                <div className="flex justify-center items-center h-8  py-4 rounded-full shadow-md cursor-pointer bg-gray-800 hover:bg-gray-700 dark:bg-[#202231] dark:hover:bg-[#2e3348]">
                  <img
                    src={chainId === 19 ? wrapsongbirdSvg : wrapflareSvg}
                    className="w-5 h-5 mx-2 text-gray-800 fill-current"
                    alt=""
                  />
                  <span className="px-3 font-medium uppercase">
                    {chainId === 19 ? "wsgb" : "wflr"}
                  </span>
                </div>
                <span className="text-gray-400">{wNat.toFixed(2)}</span>
              </div>

              <div className="w-full text-sm text-gray-400 dark:text-gray-300">
                <div className="flex justify-between">
                  <span>Allocated:</span>
                  <span className="text-right text-gray-400">
                    {allocatedAmountPercentage}%
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Active:</span>
                  <span className="text-right text-gray-400">
                    {activeAmount.toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Next Epoch: </span>
                  <span className="text-gray-400">
                    {remain > 0
                      ? `${Math.floor(remain / (24 * 3600))}d
											${Math.floor((remain % (24 * 3600)) / 3600)}h
											${Math.floor((remain % 3600) / 60)}m
										` : "0h 0m 0s"}
                  </span>
                </div>
              </div>
            </div>
            <div className="grid py-2 gap-y-2">
              {delegations?.map((provider, index) => {
                return (
                  <div
                    key={index}
                    className="rounded-[14px] text-center py-1 border border-gray-700 hover:border-gray-800 dark:hover:border-gray-600 bg-white dark:bg-[#161622]  cursor-pointer hover:shadow-sm"
                  >
                    <div
                      className="flex items-center pl-4 h-14"
                      onClick={() =>
                        handleOpenDelegateConfigureModal(providers.find((fpvd) => {
                          return fpvd.address === provider.address;
                        }) ?? ({ ...UnknownProvider, address: provider.address }))
                      }
                    >
                      <div className="flex items-center w-full">
                        <img
                          src={
                            providers.find((fpvd) => {
                              return fpvd.address === provider.address;
                            })?.logoURI ?? UnknownProvider.logoURI
                          }
                          alt=""
                          className="w-7"
                        />
                        <div className="pl-4 text-left">
                          <div>
                            {
                              providers.find((fpvd) => {
                                return fpvd.address === provider.address;
                              })?.name ?? UnknownProvider.name
                            }
                          </div>
                          <div className="text-xs text-gray-400">
                            {addressStrWithDot(provider.address, 8, 7)}
                          </div>
                        </div>
                      </div>
                      <div className="pr-6 ml-auth">
                        {" "}
                        {(provider.percentage * 100).toFixed(2)}%
                      </div>
                    </div>
                  </div>
                );
              })}
              {delegations && delegations.length === 1 ? (
                <div className="rounded-[14px] text-center py-1 border border-gray-700 hover:border-gray-800 dark:hover:border-gray-600 bg-white dark:bg-[#161622]  cursor-pointer hover:shadow-sm">
                  <div
                    className="flex items-center pl-4 h-14"
                    onClick={handleDategationSlot2}
                  >
                    <PlusSvg className="w-5 h-5 text-gray-300 fill-current dark:text-gray-400" />
                    <span className="pl-5 font-semibold text-gray-300 dark:text-gray-400">
                      Delegation Slot 2
                    </span>
                  </div>
                </div>
              ) : delegations && delegations.length === 0 ? (
                <>
                  <div className="rounded-[14px] text-center py-1 border border-gray-700 hover:border-gray-800 dark:hover:border-gray-600 bg-white dark:bg-[#161622]  cursor-pointer hover:shadow-sm">
                    <div
                      className="flex items-center pl-4 h-14"
                      onClick={handleDategationSlot1}
                    >
                      <PlusSvg className="w-5 h-5 text-gray-300 fill-current dark:text-gray-400" />
                      <span className="pl-5 font-semibold text-gray-300 dark:text-gray-400">
                        Delegation Slot 1
                      </span>
                    </div>
                  </div>
                  <div className="rounded-[14px] text-center py-1 border border-gray-700 hover:border-gray-800 dark:hover:border-gray-600 bg-white dark:bg-[#161622]  cursor-pointer hover:shadow-sm">
                    <div
                      className="flex items-center pl-4 h-14"
                      onClick={handleDategationSlot2}
                    >
                      <PlusSvg className="w-5 h-5 text-gray-300 fill-current dark:text-gray-400" />
                      <span className="pl-5 font-semibold text-gray-300 dark:text-gray-400">
                        Delegation Slot 2
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {!active ? (
              <div className="py-2">
                <div
                  className=" rounded-[14px] dark:bg-[#DAA520] text-center py-2"
                  onClick={() => {
                    if (!active) {
                      connect().catch((e) => {
                        if (e.name === "UnsupportedChainIdError") {
                          notifyError("Please connect to Songbird or Flare");
                        }
                      });
                    }
                  }}
                >
                  Connect Wallet
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {isOpenDelegateModal ? (
          <ProvidersModal
            handleCancel={handleCancelDelegateModal}
            handleOpenDelegateConfigureModal={handleOpenDelegateConfigureModal}
          />
        ) : (
          <></>
        )}
        {isOpenDelegateConfigureModal ? (
          <ProviderConfigureModal
            handleCancelDelegateConfigureModal={
              handleCancelDelegateConfigureModal
            }
            handleOpenDelegatingConfirmationModal={
              handleOpenDelegatingConfirmationModal
            }
            providerStatus={choosenProvider}
            maxPercentage={maxPercentage}
          />
        ) : (
          <></>
        )}
        {isOpenUndelegateConfirmationModal ? (
          <ProviderUndelegateConfirmation
            handleCancelDelegatingConfirmationModal={
              handleCancelDelegatingConfirmationModal
            }
            providerStatus={choosenProvider}
            handleUndelegate={handleUndelegate}
          />
        ) : (
          <></>
        )}
        {isOpenSaveConfirmationModal ? (
          <ProviderSaveConfirmation
            handleCancelDelegatingConfirmationModal={
              handleCancelDelegatingConfirmationModal
            }
            providerStatus={choosenProvider}
            delegatingAmountPercentage={delegatingAmountPercentage}
            handleDelegate={handleDelegate}
          />
        ) : (
          <></>
        )}
      </div>

      <div className="mt-[50px] text-center">
        <h4 className="text-[30px] font-bold">🔮Oracle Metrics📊</h4>
        <div className="flex text-[14px] justify-center">
          <span className="text-[#E9B83B] mx-[3px] my-auto">Sort:</span>
          <button className="rounded-lg bg-[#E9B83B] px-[6px] mx-[3px] h-[20px] my-auto" onClick={() => sortBy("reward_rate")}>Rate</button>
          <button className="rounded-lg bg-[#E9B83B] px-[6px] mx-[3px] h-[20px] my-auto" onClick={() => sortBy("votepower")}>Vote Power</button>
          <button className="rounded-lg bg-[#E9B83B] px-[6px] mx-[3px] h-[20px] my-auto" onClick={() => sortBy("fee")}>Fee</button>
          <span className="my-auto cursor-pointer" onClick={() => sortBy("assignedrandom")}><img src={ARCLogo} alt="main logo" className="w-[30px]" /></span>
          <span className="my-auto cursor-pointer" onClick={() => sortBy("upcomingrandom")}><img src={ARCLogo} alt="main logo" className="w-[30px]" style={{ opacity: 0.5 }} /></span>
        </div>
        <div className="flex justify-center">
          {
            isLoading ? (
              <div className="sm:w-96 w-full mt-[30px]">
                <CircleLoader />
              </div>
            ) : (
              <div className="w-[90%] sm:w-[320px] md:w-96">
                {providersArr.map((ele, i) => {
                  if (!metricInfoes[ele.address]) return null;
                  return (
                    <MetricCard key={i} chainId={chainId} props={metricInfoes[ele.address]} handleOpenDelegateConfigureModal={handleOpenDelegateConfigureModal} />
                  )
                })}
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};
