import { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-pagination-bar/dist/index.css';
import "./assets/css/main.css";
import { Delegate } from "./pages/delegate";
import { Rewards } from "./pages/rewards";
import { Wrap } from "./pages/wrap";
import { Archetypes } from "./pages/archetypes";
import { Layout } from "./pages/layout/Layout";
import useConnect from "./hooks/useConnect";
import { notifyError } from "./lib";
import { Oracle } from "./pages/oracles";

const App = () => {
  const { connect } = useConnect();
  useEffect(() => {
    connect().catch((e) => {
      if (e.name === "UnsupportedChainIdError") {
        notifyError("Please connect to Songbird or Flare");
      }
    });
  }, []);
  return (
    <div className="w-full dark:text-gray-200 text-gray-800 bg-gradient-to-b dark:from-[#0d0415] dark:to-[#0d0415] from-slate-150 to-slate-200 overflow-hidden">
      <Router>
        <Layout>
          <Suspense fallback={null}>
            <Routes>
              <Route path="/" element={<Wrap />} />
              <Route path="/dashboard" element={<Wrap />} />
              <Route path="/wrap" element={<Wrap />} />
              <Route path="/delegate" element={<Delegate />} />
              <Route path="/rewards" element={<Rewards />} />
              {/* <Route path="/archetypes" element={<Archetypes />} /> */}
              <Route path="/oracles" element={<Oracle />} />
            </Routes>
          </Suspense>
          <ToastContainer toastStyle={{ backgroundColor: "#0d0415" }} />
        </Layout>
      </Router>
    </div>
  );
};

export default App;
