import { useState } from "react";
import { Link } from "react-router-dom";
import { NavbarProps } from "../types";
import useConnect from "../hooks/useConnect";

import LogoImg from "../assets/icons/icon-72x72.png";
import { notifyError } from "../lib";
import { SelectMenu } from "./SelectMenu";

export const Navbar = ({ tabs, tabClick, tabId }: NavbarProps) => {
  const { connect, account, active, chainId } = useConnect();
  return (
    <>
      <nav
        role="navigation"
        className="hidden w-full mx-auto border-b border-gray-800 md:block"
      >
        <div className="container flex items-center h-20 p-8 mx-auto max-w-7xl stify-between md:items-stretch">
          <div className="flex items-center w-full h-full">
            <div
              className="flex pr-6 mr-2"
              onClick={() =>
                (window.location.href = "http://dex.oracleswap.io/en/swap")
              }
            >
              <img src={LogoImg} className="h-8 pr-2 " />
              <span className="pt-[5px] font-bold">Oracle Swap</span>
            </div>
            <ul className="items-center hidden h-full gap-6 pt-1 md:flex">
              {tabs.map((item, index) => (
                <li key={index}>
                  <Link to={item.link_url} onClick={() => tabClick(index)}>
                    <span
                      className={
                        tabId === index
                          ? "focus:outline-none border-transparent  text-sm cursor-pointer tracking-tight text-white hover:text-white py-1 px-2"
                          : "focus:outline-none border-transparent text-sm cursor-pointer text-gray-400 font-bold tracking-tight  hover:text-white py-1 px-2 gap-3"
                      }
                    >
                      {item.tab_name}
                    </span>
                  </Link>
                </li>
              ))}
              <a href="https://dex.oracleswap.io">
                <span
                  className={
                    "outline-none border-transparent text-sm cursor-pointer text-gray-400 font-bold tracking-tight  hover:text-white py-1 px-2 gap-3"
                  }
                >
                  Dex
                </span>
              </a>
              <a href="https://docs.oracleswap.io/guides/ftso">
                <span
                  className={
                    "outline-none border-transparent text-sm cursor-pointer text-gray-400 font-bold tracking-tight  hover:text-white py-1 px-2 gap-3"
                  }
                >
                  Learn
                </span>
              </a>
            </ul>
            <div className="flex items-center ml-auto gap-x-2">
              <SelectMenu chainId={chainId}></SelectMenu>
              <div className="flex items-center text-gray-400 gap-x-2">
                <div
                  className="flex items-center px-3 py-1 border-2 border-gray-100 rounded-lg hover:border-gray-700 hover:cursor-pointer bg-gray-1000 dark:border-gray-800 dark:hover:border-gray-700 gap-x-2"
                  onClick={() => {
                    if (!active) {
                      connect().catch((e) => {
                        if (e.name === "UnsupportedChainIdError") {
                          notifyError("Please connect to Songbird or Flare");
                        }
                      });
                    }
                  }}
                >
                  {active
                    ? `${account?.substring(0, 5)}...${account?.substr(
                        account.length - 3,
                        3
                      )}`
                    : "Connect Wallet"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <nav className="md:hidden">
        <div className="container items-center justify-between mx-auto max-w-7xl md:items-stretch">
          <div className="flex w-5/6 mx-auto md:hidden">
            <div className="items-center justify-between w-full py-4 smd:flex">
              <div className="focus:outline-none ">
                <div
                  className="flex items-center justify-center py-3 mr-2 text-gray-400 smd:py-0"
                  onClick={() =>
                    (window.location.href = "http://dex.oracleswap.io/en/swap")
                  }
                >
                  <img src={LogoImg} className="h-10 pr-4 smd:h-8 smd:pr-2" />
                  <span className="font-bold">Oracle Swap</span>
                </div>
              </div>
              <div className="items-center justify-around block sm:flex md:hidden gap-x-2 ">
                <div className="flex items-center ml-auto gap-x-2">
                  <SelectMenu chainId={chainId}></SelectMenu>
                  <div className="flex items-center text-gray-400 gap-x-2">
                    <div
                      className="flex items-center px-3 py-1 border-2 border-gray-100 rounded-lg hover:border-gray-700 hover:cursor-pointer bg-gray-1000 dark:border-gray-800 dark:hover:border-gray-700 gap-x-2"
                      onClick={() => {
                        if (!active) {
                          connect().catch((e) => {
                            if (e.name === "UnsupportedChainIdError") {
                              notifyError(
                                "Please connect to Songbird or Flare"
                              );
                            }
                          });
                        }
                      }}
                    >
                      {active
                        ? `${account?.substring(0, 5)}...${account?.substr(
                            account.length - 3,
                            3
                          )}`
                        : "Connect Wallet"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full py-1 border-b border-gray-800">
              <ul className="flex justify-around w-full h-full flex-wrap">
                {tabs.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link_url} onClick={() => tabClick(index)}>
                      <span
                        className={
                          tabId === index
                            ? " h-full flex items-center dark:text-gray-200 text-gray-800 tracking-tight px-2"
                            : "h-full flex items-center dark:text-gray-400 text-gray-400 tracking-tight px-2 py-1"
                        }
                      >
                        {item.tab_name}
                      </span>
                    </Link>
                  </li>
                ))}
                <li>
                  <a href="http://dex.oracleswap.io/en/swap">
                    <span
                      className={
                        "h-full flex items-center dark:text-gray-400 text-gray-400 tracking-tight px-2 py-1"
                      }
                    >
                      Dex
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://docs.oracleswap.io/guides/ftso">
                    <span
                      className={
                        "h-full flex items-center dark:text-gray-400 text-gray-400 tracking-tight px-2 py-1"
                      }
                    >
                      Learn
                    </span>
                  </a>
                </li>
              </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
