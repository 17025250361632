export const routes = [
    {
        tab_name: "Wrap",
        link_url: "/wrap",
    },
    {
        tab_name: "Delegate",
        link_url: "/delegate",
    },
    {
        tab_name: "Rewards",
        link_url: "/rewards",
    },
    // {
    //     tab_name: "Archetypes",
    //     link_url: "/archetypes",
    // },
    {
        tab_name: "Oracles",
        link_url: "/oracles",
    },
];